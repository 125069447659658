<template>
  <div class="breadcrumbs">
    <div class="container-padding">
      <div class="breadcrumbs__inner">
        <ul class="breadcrumbs__container">
          <li>
            <router-link :to="{ name: 'home' }">
              <span>Главная</span>
            </router-link>
          </li>
          <li v-for="(link, i) in links" :key="i">
            <router-link :to="link.route" v-if="link.route">
              {{ link.title }}
            </router-link>
          </li>
        </ul>
        <h1 class="breadcrumbs__page-title" v-if="title">{{ title }}</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BreadcrumbsComponent",
  props: {
    links: Array,
    title: String,
  },
};
</script>

<style lang="stylus">
.breadcrumbs {
  width 100%
  margin-bottom 30px

  &__inner {
    display flex
    flex-direction column
    gap: 5px
  }

  &__container {
    display flex
    align-items flex-start
    justify-content flex-start
    flex-wrap wrap
    gap: 10px

    li {
      display flex
      align-items center
      gap: 10px

      &:not(:last-child) {
        &:after {
          content: ""
          display block
          height: 1px
          width: 20px
          background: var(--dark-light)
        }
      }

      a,
      > span {
        font-size: 0.875em
        line-height: 22px;
        display: flex;
        align-items: center;
        color: var(--dark-light)
        transition var(--transition)

        .icon {
          width 12px
          height 12px
        }
      }
    }
  }

  &__page-title {
    width 100%
    margin 0
  }
}
</style>
